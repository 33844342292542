<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveWidget"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <tr>
          <td>Name</td>
          <td><input v-model="mWidget.Name" class="input" /></td>
        </tr>
        <tr>
          <td>Show country selection</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowCountrySelect }">
                  <a @click="mWidget.ShowCountrySelect = true">
                    <span>Yes</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowCountrySelect }">
                  <a @click="mWidget.ShowCountrySelect = false">
                    <span>No</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Show search input</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowSearchInput }">
                  <a @click="mWidget.ShowSearchInput = true">
                    <span>Yes</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowSearchInput }">
                  <a @click="mWidget.ShowSearchInput = false">
                    <span>No</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Show meeting type selection</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowMeetingtypeSelect }">
                  <a @click="mWidget.ShowMeetingtypeSelect = true">
                    <span>Yes</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowMeetingtypeSelect }">
                  <a @click="mWidget.ShowMeetingtypeSelect = false">
                    <span>No</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Default meeting type</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li
                  :class="{
                    'is-active': mWidget.PreSelectedMeetingtype === 0,
                  }"
                >
                  <a @click="mWidget.PreSelectedMeetingtype = 0">
                    <span>None</span>
                  </a>
                </li>
                <li
                  v-for="(meetingtype, index) in locationState.Meetingtypes"
                  :key="index"
                  :class="{
                    'is-active':
                      mWidget.PreSelectedMeetingtype ===
                      meetingtype.MeetingtypeId,
                  }"
                >
                  <a
                    @click="
                      mWidget.PreSelectedMeetingtype = meetingtype.MeetingtypeId
                    "
                  >
                    <span>{{
                      meetingtype.MeetingtypeId | getMeetingtypeName
                    }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Show options step</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowOptionsStep }">
                  <a @click="mWidget.ShowOptionsStep = true">
                    <span>Yes</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowOptionsStep }">
                  <a @click="mWidget.ShowOptionsStep = false">
                    <span>No</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Show booking details step</td>
          <td>
            <div class="tabs is-toggle is-small">
              <ul>
                <li :class="{ 'is-active': mWidget.ShowBookingDetailsStep }">
                  <a @click="mWidget.ShowBookingDetailsStep = true">
                    <span>Yes</span>
                  </a>
                </li>
                <li :class="{ 'is-active': !mWidget.ShowBookingDetailsStep }">
                  <a @click="mWidget.ShowBookingDetailsStep = false">
                    <span>No</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Styling</td>
          <td></td>
        </tr>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import widgetProvider from '@/providers/widget'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    widget: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mWidget: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {
    this.mWidget = JSON.parse(JSON.stringify(this.widget))
  },

  methods: {
    saveWidget() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        widgetProvider.methods
          .updateLocationSearchWidgets(this.mWidget)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
  },
}
</script>
